



























import mixins from 'vue-typed-mixins'
import Static from '@/builder/sections/section/static/mixins/Static'
export default mixins(Static).extend({
  name: 'Regulations',
  created () {
    if (!this.regulations) {
      this.isLoading = true
      this.fetchRegulations().finally(() => {
        this.isLoading = false
      })
    }
  }
})
